"use client";
import styles from "./footer.module.scss";
import headerStyles from "../Header/header.module.scss";
import bannerStyles from "../Banner/banner.module.scss";
import Image from "next/image";
import { AstroImages, FOOTER_DATA, FOOTER_LINKS } from "../constants";
import { useRouter } from "next/navigation";
import useAppContext from "@/customhooks/useAppContext";
import {
  RUPEE_ORANGE_ICON,
  RUPEE_PURPLLE_ICON,
} from "@/utils/imageRelativePaths";
export default function Footer() {
  const router = useRouter();
  const context = useAppContext();
  const { auth, setPopups } = context;
  const token = auth?.token;
  const currentYear = new Date().getFullYear();
  const handleNavigation = (
    link: string = "",
    skip_login = false,
    external = false
  ) => {
    // if (!skip_login && (!token || token.length == 0)) {
    //   setPopups({ showLoginPopup: true });
    //   return;
    // }
    if (external) return window.open(link, "_blank");
    if (link.length) {
      router.push(link);
    }
  };
  return (
    <div className={styles["footer_cont"]}>
      <div className={styles["footer_content_cont"]}>
        <div className={styles["footer_content"]}>
          <div className={styles["button_section"]}>
            <div
              className={`${headerStyles["logo_cont"]} ${styles["logo_cont"]}`}
            >
              <img
                src={
                  "https://media.chingari.io/apipublic/chingari-web-assets/images/astro/astro-icon.png"
                }
                alt="Astro"
              />
              <p>ASTROLIVE</p>
            </div>
            <div
              className={`${bannerStyles["banner_btn"]} ${styles["footer_btn"]}`}
              onClick={() => router.push("/chat")}
            >
              <div className={bannerStyles["icon_cont"]}>
                <Image src={AstroImages.MESSAGE_ICON} alt="" />
              </div>
              Chat With{" "}
              <span className={bannerStyles["astrologer"]}>Astrologer</span>
              <span className={bannerStyles["rate"]}>
                ({" "}
                <Image
                  src={RUPEE_ORANGE_ICON}
                  alt="rupee-ic"
                  height={15}
                  width={15}
                />{" "}
                10/min)
              </span>
            </div>
            <div
              className={`${bannerStyles["banner_btn"]} ${styles["footer_btn"]}`}
              onClick={() => router.push("/call")}
            >
              <div className={bannerStyles["icon_cont"]}>
                <Image src={AstroImages.PHONE_ICON} alt="" />
              </div>
              Talk to{" "}
              <span className={bannerStyles["astrologer"]}>Astrologer</span>{" "}
              <span className={bannerStyles["rate"]}>
                ({" "}
                <Image
                  src={RUPEE_ORANGE_ICON}
                  alt="rupee-ic"
                  height={15}
                  width={15}
                />{" "}
                15/min)
              </span>
            </div>
          </div>
          {FOOTER_DATA.map((footerLinks, footerindex) => (
            <div className={styles["links_section"]} key={footerindex}>
              {footerLinks.map((item, index) => (
                <p
                  className={styles["link"]}
                  key={index}
                  onClick={() =>
                    handleNavigation(item.link, false, item.external)
                  }
                >
                  {item.text}
                  {item?.text === "Book a Pooja" && (
                    <div className={styles.newTag}>New</div>
                  )}
                </p>
              ))}
            </div>
          ))}
        </div>
        <p className={styles["copy_right"]}>
          @{currentYear} Tech4Billion Media Pvt. Ltd. All Rights Reserved
        </p>
        <div className={styles["policy_links"]}>
          {FOOTER_LINKS.map((item, index) => (
            <p
              className={styles["link"]}
              key={index}
              onClick={() => handleNavigation(item.link, true)}
            >
              {item.text}
              <span>|</span>
            </p>
          ))}
        </div>
        <p className={styles["small_text"]}>GST Number: 29AAHCT9333P1ZF</p>
        <p className={styles["small_text"]}>
          Address: 62/63 The Pavilion, WeWork The Pavilion, Church Street,
          Bangalore, Karnataka, 560001
        </p>
        <div className={styles["social"]}>
          <a href="https://www.instagram.com/astrolive_future" target="_blank">
            <Image src={AstroImages.INSTAGRAM_ICON} alt={"instagram"} />
          </a>
          <a href="mailto:help@astrolive.app" target="_blank">
            <Image src={AstroImages.MAIL_ICON} alt={"email"} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61552253798986"
            target="_blank"
          >
            <Image src={AstroImages.FB_ICON} alt={"facebook"} />
          </a>
          {/* <Image src={AstroImages.INSTAGRAM_ICON} alt={""}  onClick={() => handleNavigation(`https://www.instagram.com/astrolivebychingari/?igsh=MmxzbTZ1Nm5rNWNu`, true)}  /> */}
          {/* <Image src={AstroImages.MAIL_ICON} alt={""}  onClick={() => handleNavigation(`mailto:help@astrolive.app`, true)}/> */}
          {/* <Image src={AstroImages.FB_ICON} alt={""}  onClick={() => handleNavigation(item.link, true)}/> */}
        </div>
      </div>
    </div>
  );
}
